<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">
			<div v-if="$i18n.locale == 'en'">
				<h2 class="display-4 mb-0"
						v-html="$t('smart_frenchcontrat_1')" />
				<h2 v-html="$t('smart_frenchcontrat_2')" />
				<p v-html="$t('smart_frenchcontrat_3')" />

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-1.png" />
						<br /> <br /> <b v-html="$t('smart_frenchcontrat_4')" /> <a target="_target"
							 href="https://wallet.fraction.re">https://wallet.fraction.re</a>
						<br />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouvrir-compte.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_5')" />
						<br />
						<br />
					</div>
				</div>

				<h3 class="mt-12"
						v-html="$t('smart_frenchcontrat_6')" />

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-1.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_7')" />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-2.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_8')" />
						<br />
						<br />
					</div>
				</div>

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-3.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_9')" />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-4.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_10')" />

						<br />
						<br />
					</div>
				</div>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-5.png" />
						<br /> <br />

						<b v-html="$t('smart_frenchcontrat_11')" />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-6.png" />
						<br /> <br />

						<b v-html="$t('smart_frenchcontrat_12')" />

						<br />
						<br />
					</div>
				</div>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-7.png" />
						<br /> <br />
						<b v-html="$t('smart_frenchcontrat_13')" />
					</div><br />

				</div>

				<div class="row ">
					<h3 class="mt-12"
							v-html="$t('smart_frenchcontrat_14')" />
					<p v-html="$t('smart_frenchcontrat_15')" />
				</div>
			</div>
			<div v-if="$i18n.locale == 'fr'">
				<h2 class="display-4 mb-0">
					Renseigner des données KYC/AML pour signer des contrats de droit Français.</h2>
				<h2>
					Principe de base</h2>
				<p>
					Un titulaire de compte veut renseigner des données KYC/AML dans l'écosytème Fraction.re pour pouvoir signer des contrats de droit Français.
				</p>

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/berlcoin-1.png" />
						<br /> <br /> <b> Aller sur <a target="_target"
								 href="https://wallet.fraction.re">https://wallet.fraction.re</a>
						</b> <br />
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouvrir-compte.png" />
						<br /> <br /> <b> Cliquez sur "Nouveau sur Fraction ?" puis Identification KYC/AML </b><br />
						<br />
					</div>
				</div>

				<h3 class="mt-12">
					Renseignez vos informations d'identité</h3>

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-1.png" />
						<br /> <br /> <b>Vos données de contact
						</b>
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-2.png" />
						<br /> <br /> <b> Vos données personnelles
						</b><br />
						<br />
					</div>
				</div>

				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-3.png" />
						<br /> <br /> <b>Vos données fiscales
						</b>
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-4.png" />
						<br /> <br /> <b> Vos données bancaires
						</b><br />
						<br />
					</div>
				</div>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-5.png" />
						<br /> <br /> <b>Vos justificatifs
						</b>
					</div><br />
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-6.png" />
						<br /> <br /> <b> Imprimer vos clés et switchez pour confirmer
							<br />
						</b><br />
						<br />
					</div>
				</div>
				<div class="row ">
					<div class="col-6 col-md-6 mb-4">
						<img style="border: 1px solid silver; max-width:100%"
								 src="https://media.berlcoin.fr/ouverture-7.png" />
						<br /> <br /> <b>Vérifiez et validez. Vous allez recevoir un mail de confirmation.
						</b>
					</div><br />

				</div>

				<div class="row ">
					<h3 class="mt-12">
						Coût de l'opération</h3>
					<p> Gratuit
					</p>
				</div>
			</div>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'francais',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
